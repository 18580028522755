// Login
export const POST_LOGIN = "/login"

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"


// Company
export const COMPANY_URL = "/api/company"

// Product
export const PRODUCT_URL = "/api/products"

// Company
export const MASTER_URL = "/api/masters?access_type="
export const MASTER_OPTIONS_URL = "/api/masters/options"
export const PRODUCT_OPTIONS_URL = "/api/products/getProductOptions"

// Employee
export const EMPLOYEE_URL = "/api/employee"

// Clients
export const CLIENT_URL = "/api/clients"

// Order
export const ORDER_URL = "/api/orders"
export const RX_UPLOAD = "/api/orders/rxupload"
export const INVOICE_URL = "/api/invoice"
export const INVOICE_CANCEL_URL = "/api/invoice/cancelinvoice"

// Challans
export const ORDERFORM_URL = "/api/Challans/genrateOrderform"
export const CHALLAN_URL = "/api/challans"
export const GETORDERFORMS_URL = "/api/getOrderforms"

// Client Options Dropdown
export const DDClients_URL = "/api/selectors/getClientsOptions"

export const ORDER_OPTIONS_URL = "/api/orders/options"

export const ROLE_PERMISSION = "/api/roles/modules"
export const ROLE_PERMISSION_SAVE = "/api/roles/savePermission"
export const ROLE_PERMISSION_GET = "/api/roles/permissions"

// Settings
export const PROFILE = "/api/profile"
export const INVOICE_CONFIG = "/api/profile/invoice"
export const SUBSCRIPTION = "/api/profile/subcription"

// Notification
export const WHATSAPP = "/api/notifications/whatsapp"
export const WHATSAPP_TEST_URL = "/api/notifications/whatsapptest"
export const WHATSAPP_SHAREADDRESS_URL = "/api/notifications/whatsappshareaddress"
export const SMS_CONFIG = "/api/notifications/sms"
export const SMS_CONFIG_TEST = "/api/notifications/smstest"
export const TEMPLATE_URL = "/api/notifications/templates"
export const NSC_URL = "/api/notifications/notificationSettings"
export const SEND_URL = "/api/notifications/send"
export const MAIL_URL = "/api/smtp"

export const CONVERTINVOICE_URL = "/api/invoice/convertoinvoice"


// Payments
export const DUES_URL = "/api/finance/dues"
export const PAYMENT_URL = "/api/payments"

// Process
export const PROCESS_URL = "/api/process"

// Seatch Clients
export const SEARCHCLIENTS = '/api/search/q'
export const FILTERS = '/api/search/getFilterOptions'

export const BULK_INVOICES = '/api/invoice/bulk'