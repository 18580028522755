import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Container,
    Row
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Mdatatable from "components/Datatables/Mdatatables"
import { get } from "helpers/api_helper"
import { customFormatDate } from 'helpers/basic_helper'
import { GETORDERFORMS_URL } from 'helpers/url_helper'
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"

const isType = 'orderform'
const Orderform = ({isCall = false}) => {
    const dispath = useDispatch()
    const {loading} =  useSelector(state => state.Challans);
    const [challans, setChallans] = useState([]);
    const [challansLoad, setChallansLoad] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [challanData, setChallanData] = useState(null);

    useEffect(async () => {
        setChallansLoad(true)
        const res = await get(`${GETORDERFORMS_URL}`);
        if (res.status == 200) {
            setChallansLoad(false)
            setChallans(res.response)
        }
    }, [])

    const printPdf = (orderform) => {
        const CN = orderform.orderform_number;
        window.open(`/orderform/print?_orderform=${CN}`, '_blank');
    };

    /**
     * DataTable with Condition based row formating
     */
    let datatableData = [
        {
            accessorKey: 'id',
            header: 'Id'
        }, {
            accessorFn: (row) => <a title="Click to View Orderform" href={`/orderform/print?_orderform=${row.orderform_number}`} target="_blank" rel="noreferrer">
                # {row.orderform_number} <br />   <span className={`ms-2 badge badge-soft-warning font-size-12`}>{customFormatDate(row?.orderform_date, 'DD MMM, YY')}</span>
            </a>,
            header: 'OrderForm #'
        }, {
            accessorFn: (row) => <span className={`text-success font-size-14`}>{row.order_id}</span>,
            header: 'Order #'
        }, {
            accessorKey: 'clientname',
            header: 'Client #'
        }, {
            accessorKey: 'order_date',
            header: 'Order Date',
            accessorFn: (row) => customFormatDate(row?.order_date, 'DD MMM, YY')
        }, {
            accessorKey: 'due_date',
            header: 'Due Date',
            accessorFn: (row) => customFormatDate(row?.due_date, 'DD MMM, YY')
        }
    ];

    if(isCall){
        return <Mdatatable
                title={`All Orderforms`}
                columns={datatableData}
                data={challans}
                isAdd={false}
                loading={challansLoad}
                btnloading={loading}
                enableRowAction={true}
                userModules={{
                    callback: printPdf,
                    istype: 'orderform'
                }}
            />
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>{isType} | KS-Lab</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Masters" breadcrumbItem={isType} />

                    <Row style={{ overflowY: 'auto' }}>
                        <Col>
                        <Mdatatable
                            title={`All Orderforms`}
                            columns={datatableData}
                            data={challans}
                            isAdd={false}
                            loading={challansLoad}
                            btnloading={loading}
                            enableRowAction={true}
                            userModules={{
                                callback: printPdf,
                                istype: 'orderform'
                            }}
                        />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Orderform
