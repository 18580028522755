import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Container,
    Row
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Mdatatable from "components/Datatables/Mdatatables"
import { deliveryModeOptions } from 'constants/layout'
import { get } from "helpers/api_helper"
import { CHALLAN_URL } from 'helpers/url_helper'
import "react-datepicker/dist/react-datepicker.css"
import { useSelector } from "react-redux"
import { customFormatDate } from 'helpers/basic_helper'

const isType = 'challan'
const Challans = () => {
    const {loading} =  useSelector(state => state.Challans);
    const [challans, setChallans] = useState([]);
    const [challansLoad, setChallansLoad] = useState(false);

    useEffect(async () => {
        setChallansLoad(true)
        const res = await get(`${CHALLAN_URL}`);
        if (res.status == 200) {
            setChallansLoad(false)
            setChallans(res.response)
        }
    }, [])

    /**
     * DataTable with Condition based row formating
     */
    let datatableData = [
        {
            accessorKey: 'id',
            header: 'Id'
        }, {
            accessorKey: 'challan_number',
            header: 'Challan Number',
            accessorFn: (row) => {
                return  <>
                    <a title="Click to view challan" href={`/challan/print?_challan=${row.challan_number}`} target="_blank" rel="noreferrer">
                        # {row.challan_number}
                    </a><br />
                    <span className={`badge badge-soft-${row.is_dispath == 1 ? 'success' : 'danger'} font-size-11`}>{row.is_dispath == 1 ? 'Dispatched' : 'Pending'}</span>
                    <span className={`ms-2 badge badge-soft-warning font-size-11`}>{deliveryModeOptions?.filter(v => v.value == row.delivery_mode)[0]?.label}</span>
                </>
            }
        }, {
            accessorKey: 'challan_date',
            header: 'Challan Date',
            accessorFn: (row) => customFormatDate(row?.challan_date, 'DD MMM, YY')
        }, {
            accessorKey: 'clientname',
            header: '#Client'
        }, {
            accessorKey: 'order_number',
            header: 'Order Number'
        }, {
            accessorKey: 'order_date',
            header: 'Order Date',
            accessorFn: (row) => customFormatDate(row?.order_date, 'DD MMM, YY')
        }, {
            accessorKey: 'due_date',
            header: 'Due Date',
            accessorFn: (row) => customFormatDate(row?.due_date, 'DD MMM, YY')
        }
    ];

    const printPdf = (challanNumber) => {
        const CN = challanNumber.challan_number;
        window.open(`/challan/print?_challan=${CN}`, '_blank');
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>{isType} | KS-Lab</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Masters" breadcrumbItem={isType} />

                    <Row>
                        <Col>
                            <Mdatatable
                                title={`All Challans`}
                                columns={datatableData}
                                data={challans}
                                isAdd={false}
                                loading={challansLoad}
                                btnloading={loading}
                                enableRowAction={true}
                                userModules={{
                                    callback: printPdf
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Challans
