import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import "./datatables.scss";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import nodataimg from 'assets/images/nodata.png'

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from "moment";

const MyExportCSV = (props) => {
  const handleClick = () => {
    // eslint-disable-next-line react/prop-types
    props.onExport();
  };
  return <button className="btn" onClick={handleClick}>Export to CSV</button>
};


const MySearch = () => {
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Send Axios request here
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  return (
    <div>
      <input
        className="form-control"
        type="text"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  );
};

const { SearchBar } = Search;

// eslint-disable-next-line react/prop-types
const Datatables = ({ filename= '', rowsLength = false, isPaginationProvider = true, handleAddButton, title, columns, rows, loading, selectField, defaultSorted, keyField, btnTitle = 'Add', isAdd = true, isTableHead = true, isDisabled = false }) => {
  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    // eslint-disable-next-line react/prop-types
    { text: 'All', value: rowsLength }
  ];

  const pageOptions = {
    sizePerPage: sizePerPageList[0].value,
    sizePerPageList: sizePerPageList,
    totalSize: rowsLength,
    custom: true,
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              {
                (rows?.length && typeof rows !== 'string') > 0 ?

                  isPaginationProvider ? (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField={keyField}
                      columns={columns}
                      data={rows} >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField={keyField}
                          columns={columns}
                          data={rows}
                          search
                          exportCSV={
                            {
                              fileName: `${filename != '' ? filename : `${moment().format('DD_MM_YYYY')}`}.csv`
                            }
                          }
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              {
                                isTableHead ?
                                <Row>
                                  <Col sm="7">
                                    <CardTitle className="h2" style={{ marginTop: "10px" }}>
                                      {title}
                                    </CardTitle>
                                    <span className="mobile-view-csv">
                                      <MyExportCSV {...toolkitProps.csvProps} />
                                    </span>
                                  </Col>

                                  {selectField}

                                  <Col xl="1" className="pull-right desktop-view-csv">
                                    <MyExportCSV {...toolkitProps.csvProps}/>
                                  </Col>

                                  <Col>
                                    <div className="search-box me-2 mb-2 d-inline-block" style={{ width: "100%" }}>
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  {
                                    isAdd ?
                                    <Col md="2">
                                      <Button
                                        disabled={isDisabled}
                                        color="primary"
                                        style={{ float: "right" }}
                                        onClick={handleAddButton}
                                        onKeyDown={handleAddButton}
                                      >
                                        <i className={`fas ${loading ? "fa-spinner fa-spin" : "fa-plus" }`}>{" "}{btnTitle}</i>
                                      </Button>
                                    </Col> : ''
                                  }
                                </Row> : ''
                              }

                              <Row>
                                {
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={keyField}
                                        responsive
                                        bordered={true}
                                        striped={true}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        sort={defaultSorted}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                }
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }
                    </PaginationProvider>
                  ) : (
                    <ToolkitProvider
                      keyField={keyField}
                      columns={columns}
                      data={rows}
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            {
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={keyField}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    sort={defaultSorted}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            }
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )
                  :
                  <Row>
                    {
                      isTableHead ? 
                      <Col xl="10" sm="11">
                        <CardTitle className="h3" style={{ marginTop: "10px" }}>{title}</CardTitle>
                      </Col> : ''
                    }

                    {
                      isAdd && 
                      <Col xl="2" sm="2">
                        <Button
                          color="primary"
                          style={{ float: "right" }}
                          onClick={handleAddButton}
                        >
                          <i className="fas fa-plus"></i> {btnTitle}
                        </Button>
                      </Col>
                    }
                    <Row>
                    </Row>
                      <Col className="text-center">
                        {
                          (loading && rows?.length == 0) ? <span><i className="fas fa-spinner fa-spin"></i> loading...</span> : (rows?.length ? rows : (
                            <>
                              <img src={nodataimg} width={160}/><br />
                              <h4>No data found</h4>
                            </>
                          ))
                        }
                      </Col>
                  </Row>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Datatables
