import { customFormatDate } from 'helpers/basic_helper'
import {
    Col,
    Row,
} from "reactstrap"
import Teeth from './components/teeth'

const Labslip = ({data}) => {
    const teeth = data.products.map(v => JSON.parse(v.teeth)).reduce((prev, current) => [...prev, ...current])
    return (
        <div>
            <Row>
                <Col>
                <b>{data?.org?.title}</b> <br />
                {data?.org?.address}</Col>
                <Col>Email: {data?.org?.ac_email}<br />{data?.org?.website.replace('https://', 'www.')}</Col>
            </Row>
            
            <Row className="my-2">
                <Col className="text-center"><h5>LAB SLIP</h5></Col>
            </Row>

            <Row style={{marginBottom: '-15px'}}>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td>
                                <span>Order #</span><br />
                                <strong>{data?.order_number}</strong>
                            </td>
                            <td>
                                <span>Model #</span><br />
                                <strong>{data?.case_no}</strong>
                            </td>
                            <td>
                                <span>Order Date</span><br />
                                <strong>{customFormatDate(data.order_date, "DD MMM, h:mm A")}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Due Date</span><br />
                                <strong>{customFormatDate(data.due_date, "DD MMM, h:mm A")}</strong>
                            </td>
                            <td>
                                <span>Patient</span><br />
                                <strong>{data.patient_name}</strong>
                            </td>
                            <td>
                                <span>Printed</span><br />
                                <strong>{customFormatDate(data.labslip_date, "DD MMM, h:mm A")}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Row>
            <Row style={{marginBottom: '-15px'}}>
                <table className='table table-bordered'>
                    <tbody>
                        <tr>
                            <td colSpan={2} width={50}>
                                <strong>Dr. {data?.clientname}</strong>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        {
                            data?.products?.map((v, i) => {
                                return <tr key={`LSP_${i}`}>
                                    <td colSpan={2} width={50}>{v.product}</td>
                                    <td width={50} colSpan={2} className='text-end'>{v.atype}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </Row>

            <Row>
                <Teeth values={ teeth } _class="teeth_tr" />
            </Row>
            
            <Row className='mt-1'>
                <table className='table table-bordered'>
                    <tbody>
                        <tr>
                            <td>
                                <span>Units: </span>
                                <strong>{data?.units}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Enclosure: </span>
                                <span>{
                                    Object.entries(JSON.parse(data?.enclosure))
                                    .map(([key, value]) => value !== "" ? `${key}: ${value}` : key)
                                    .join(', ')
                                }</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Shade: </span>
                                <strong>{data?.shades?.map(v => v.title).join(', ')}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Priority: </span>
                                <strong>{data?.priority_title || ''}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Comment: </span>
                                <strong>{data?.remark}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Row>
        </div>
    )
}

export default Labslip
