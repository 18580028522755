import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import {
    Col,
    Container,
    Modal,
    Row,
    Card,
    CardBody
} from "reactstrap";
import Select from 'react-select';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { userModuleData } from 'helpers/basic_helper';
//i18n
import Mdatatable from "components/Datatables/Mdatatables";
import Notifications from "components/Notifications";
import { post, get } from 'helpers/api_helper';
import { CONVERTINVOICE_URL, INVOICE_CANCEL_URL, INVOICE_URL, SEND_URL, WHATSAPP_TEST_URL } from 'helpers/url_helper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getInvoices } from "store/actions";
import PrintModel from "./print";
import { months } from 'constants/layout'
import { clientOptions } from "store/actions";

const isType = 'invoice'

const inputObj = {
    code: null,
    remark: ""
}

const confirmmationData = {
    date: new Date(),
    remark: ""
}

const Invoices = () => {
    const dispatch = useDispatch()
    const invoiceType = window?.location?.pathname.includes('proforma') ? 'Proforma' : '';

    const { DDOptions } = useSelector(state => state.Master);
    const { loading, invoices } = useSelector(state => state.Invoices);
    const [dataRows, setDataRows] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [confirmationInput, setConfirmationInput] = useState(inputObj);
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)
    const [confirmConvertModal, setConfirmConvertModal] = useState(null)
    const [confirmConvertData, setConfirmConvertData] = useState(confirmmationData)
    const [isOrdersList, setIsOrdersList] = useState(null)
    const [convertingOrders, setConvertingOrders] = useState({lock:[], revert:[]})
    const [filterMonth, setFilterMonth] = useState(null)
    const [filterClient, setFilterClient] = useState(null)
    const [filterInvoice, setFilterInvoice] = useState(null)
    

    const getData = (invoiceType) => {
        const filterObj = {
            'invoice': filterInvoice || '',
            'month': filterMonth?.value || '',
            'client': filterClient?.value || '',
            'type': invoiceType ? 'Proforma' : ''
        }        
        const queryParams = new URLSearchParams(filterObj).toString();
        dispatch(getInvoices({url:`${INVOICE_URL}?${queryParams}`}))
    }
    
    useEffect(() => {
        dispatch(clientOptions({type: 'challan'}))
    },[])

    useEffect(async() => {
        getData(invoiceType)
        const data =  await userModuleData('invoice', 'invoices')
        setIsActions(data)
        if(data){
            data.add = false
            data.pdf = true
            data.sendwp = true
            data.delete = false
            data.edit = false
            data.lock = true
            setIsActionColumn(true)
        }
    } ,[invoiceType])

    useEffect(() => {
        setDataRows(invoices?.map((v, i) => {
            v['sr'] = i+1;
            return v
        }))
    }, [invoices])

    // table Column Data
    let datatableData = [
        {
            accessorKey: 'invoice_number',
            header: `${invoiceType} Invoice`
        },{
            accessorKey: 'clientname',
            header: 'Client / Doctor'
        },{
            accessorKey: 'invoice_date',
            header: 'Invoice Date (DDMMYYYYY)'
        },{
            accessorKey: 'invoice_total',
            accessorFn: (row) => parseFloat(row.invoice_total).toLocaleString('en-IN', {
                style: 'currency',
                currency: 'INR',
            }),
            header: 'Total Amount ( ₹ )'
        }
    ];

    if(invoiceType !== 'Proforma'){
        datatableData.splice(2, 0,{header: 'Proforma Invoice', accessorKey: 'proforma'})
    }

    const closeModel = (data) => {
        setIsOpen(data)
        setDataRows([])
    }

    const cancelinvoiceConfirm = async () => {
        const res = await post(INVOICE_CANCEL_URL, confirmationInput)
        if(res.status == 200){
            Notifications(`Invoice Cancelled.!`, 'error')
            getData()
            setConfirmationInput(inputObj)
            setOpenConfirmationModal(false)
        }else{
            Notifications(`Please, try again.!`, 'error')
        }
    }
    
    const ConvertInvoice = async(INO) => {

        const Obj = {
            "proforma_invoice_number": INO?.invoice_number,
            "invoice_date": confirmConvertData?.date,
            "remark": confirmConvertData?.remark,
            'orders': JSON.stringify(convertingOrders)
        }
        const res = await post(`${CONVERTINVOICE_URL}`, Obj);
        
        if(res?.status === 200){
            setConvertingOrders({lock: [], revert: []})
            setConfirmConvertData(confirmmationData)
            setConfirmConvertModal(false)
            window.open(`/invoice/print?invoice_number=${res?.response?.invoice_no}`, '_blank');
            getData(invoiceType);
        }
    }

    const wpinvoice = async(data, type) => {
        const res = await post(`${SEND_URL}`, {...data, type});
        if(res?.status === 200){
            Notifications(`Invoice Send to Whatsapp.!`,'success')
        }else{
            Notifications(`Customer hasn't registered for WhatsApp or the WhatsApp number doesn't exist.`, 'error')
        }
    }

    useEffect(() => {
        getOrders()
    }, [confirmConvertModal])

    const getOrders = async() => {
        if(confirmConvertModal){
            const res = await get(`/api/invoice/piorders?_invoice=${confirmConvertModal.invoice_number}`)
            if(res?.status == 200) {
                const data = res?.response[0]?.order_no.split(', ');
                setIsOrdersList(data);
                setConvertingOrders({ lock: data, revert: [] })
            }
        }
    }

    const handleCheckbox = (e) => {
        const input = e.target;
        if (input.dataset.type === 'lock') {
            setConvertingOrders(prev => ({
                lock: [...prev.lock, input.value],
                revert: prev.revert.filter(item => item !== input.value)
            }));
        } else {
            setConvertingOrders(prev => ({
                lock: prev.lock.filter(item => item !== input.value),
                revert: [...prev.revert, input.value]
            }));
        }
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | {isType}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="Masters"
                        breadcrumbItem={isType}
                    />
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={3}>
                                            <label>Search by Order or Modal Number</label>
                                            <input className="form-control" placeholder="Please Search using Invoice Number" onChange={(e) => setFilterInvoice(e.target.value)} />
                                        </Col>
                                        <Col md={3}>
                                            <label>Select Client or Doctor</label>
                                            <Select
                                                styles={{
                                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                                }}
                                                onChange={(e) => {
                                                    setFilterClient(e)
                                                }}
                                                isClearable={true}
                                                value={filterClient}
                                                options={DDOptions?.clients}
                                                name={'client'}
                                                required={false}
                                                placeholder={'Select Client'}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <label>By Month</label>
                                            <Select
                                                styles={{
                                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                                }}
                                                onChange={(e) => {
                                                    setFilterMonth(e)
                                                }}
                                                isClearable={true}
                                                value={filterMonth}
                                                options={months}
                                                name={'month'}
                                                required={false}
                                                placeholder={'Select Month'}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <button className="btn btn-primary" style={{marginTop: '28px'}} onClick={() => getData(invoiceType)}>Filter Invoices</button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        
                                {
                                    dataRows &&
                                    <Mdatatable
                                        enableRowAction={isActionColumn}
                                        userModules={isActions}
                                        title={`All ${invoiceType} Invoices`}
                                        columns={datatableData}
                                        data={dataRows}
                                        loading={loading}
                                        Other={{
                                            isProforma: invoiceType,
                                            isInvoice: true,
                                            callback: (data) => {
                                                window.open(`/invoice/print?invoice_number=${data.invoice_number}${invoiceType ? '&type=proforma': ''}`, '_blank');
                                            },
                                            cancelinvoice: (IN) => setOpenConfirmationModal(IN),
                                            convertInvoice: (INO) => setConfirmConvertModal(INO),
                                            sendMsg: (data) => wpinvoice(data, 'proforma')
                                        }}
                                    />
                                }

                        {
                            dataRows && <PrintModel isOpen={isOpen} closeModel={closeModel}/>
                        }

                </Container>
            </div>
            
            {
                openConfirmationModal && 
                <Modal
                    size="md"
                    isOpen={openConfirmationModal !== false}
                    toggle={() => setOpenConfirmationModal(false)}
                    centered={true}
                >
                    <div className="modal-body">
                        <p>Please Enter Invoice Number: <b><u>{openConfirmationModal}</u></b> for Cancellation.</p>
                        <label>Invoice Date*</label>
                        <input name="date" onChange={(e) => setConfirmationInput(prev => ({...prev, code: e.target.value}))} className="form-control" /> <br />
                        <label>Remark*</label>
                        <input name="remark" onChange={(e) => setConfirmationInput(prev => ({...prev, remark: e.target.value}))} className="form-control" />
                    </div>
                    <span className="text-danger ms-5 mb-2 fw-bold" style={{fontSize: '14px'}}>Once the invoice is canceled, it cannot be reverted back.</span>
                    <div className="modal-footer">
                        <button 
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setOpenConfirmationModal(false)} >Close</button>
                        <button 
                            type="button"
                            disabled={ (openConfirmationModal == confirmationInput.code && confirmationInput.remark.length > 5) ? false : true }
                            className={`btn btn-danger`}
                            onClick={cancelinvoiceConfirm} >Delete</button>
                    </div>
                </Modal>
            }

            {
                confirmConvertModal && 
                <Modal
                    size="md"
                    isOpen={confirmConvertModal !== false}
                    toggle={() => setConfirmConvertModal(false)}
                    centered={true}
                >
                    <div className="modal-body">
                        {/* <p>Please Enter Invoice Number: <b><u>{confirmConvertData}</u></b> for Convert to Invoice.</p> */}
                        <div className="row">
                            <span className="text-danger mb-2 fw-bold" style={{fontSize: '14px'}}>Once the invoice is Locked, it cannot be reverted back.</span>
                            <div className='col-6'>
                                <label>Invoice Date*</label>
                                <DatePicker
                                    className="form-control"
                                    minDate={new Date(confirmConvertModal.invoice_date.split('-').reverse().join('-'))}
                                    // maxDate={new Date(confirmConvertModal?.invoice_due_date.split('-').reverse().join('-'))}
                                    selected={new Date()}
                                    onChange={(date) => setConfirmConvertData(prev => ({...prev, date: date}))} />
                            </div>
                            <div className='col-6'>
                                <label>Remark*</label>
                                <input name="remark" onChange={(e) => setConfirmConvertData(prev => ({...prev, remark: e.target.value}))} className="form-control" />
                            </div>
                        </div>

                        <div className="row">
                           
                            {
                                isOrdersList ? 
                                <div style={{ maxHeight: '480px', overflowX: 'auto' }}>
                                    <table className="table table-borderd">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Order No</th>
                                                <th>Lock</th>
                                                <th>Cancel</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ overflowY: 'auto' }}>
                                            {isOrdersList?.map((v, i) => {
                                                return <tr key={`OLPI_${i}`}>
                                                    <td>{i+1}</td>
                                                    <td>{v}</td>
                                                    <td><input type="radio" value={v.trim()} name={`sta${v}`} defaultChecked={true} data-type="lock" onClick={handleCheckbox} /></td>
                                                    <td><input type="radio" value={v.trim()} name={`sta${v}`} data-type="cancel" onClick={handleCheckbox} /></td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                : <div className="text-center p-3">
                                    <br />
                                    <div style={{margin: '0 auto'}}>
                                        <div className="loader" style={{ position: 'absolute', margin: '-2.5% 42.5%'}}></div>
                                        <img src="https://dglab.keepsmiles.in/api/uploads/241002/241002_print.png" style={{ width: '22px', left: '26px', top: '30px' }}/>
                                    </div>
                                    <br />
                                    <b>Please Wait...</b>
                                </div>
                            }
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button 
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setConfirmConvertModal(false)} >Close</button>
                        <button 
                            type="button"
                            className={`btn btn-danger`}
                            onClick={() => ConvertInvoice(confirmConvertModal)} >Lock Invoice</button>
                    </div>
                </Modal>
            }
        </React.Fragment>
    )
}

export default Invoices