import { Modal } from "reactstrap"

const ValidateModal = ({isOpen = false, data=[], callback}) => {
    return (
        <Modal
            zIndex="9999"
            size="lg"
            isOpen={isOpen}
            centered={true}
        >
            <div className="modal-header">
                <h4>Patient existing with below orders</h4>
            </div>
            <div className="modal-body">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Order Date</th>
                            <th>Order#</th>
                            <th>Case#</th>
                            <th>Product</th>
                            <th>Product Type</th>
                            <th>Product Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map((v, i) => {
                                return <tr key={`PVM_${i+1}`}>
                                    <td>{i+1}</td>
                                    <td>{v.in_date}</td>
                                    <td>{v.case_no}</td>
                                    <td>{v.order_number}</td>
                                    <td>{v.product_title}</td>
                                    <td>{v.productcategory_title}</td>
                                    <td>{v.producttype_title}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="modal-footer">
                <button 
                    type="button"
                    title="Create order"
                    className="btn btn-primary"
                    onClick={() => {
                        callback(false);
                    }} >Continue</button>
                <button 
                    type="button"
                    title="Back to New Order"
                    className="btn btn-success"
                    onClick={() => {
                        callback(true);
                    }} > Back to New Order</button>
            </div>
        </Modal>
    )
}

export default ValidateModal