const Teeth = ({ values, isBorder = true, _class = "", isJobcard = false, callback=() => {} }) => {
    const border = isBorder ? 'THborder' : ''

    return (
        <table className={`teeth_table ${_class} w-100`}>
            <tbody>
                <tr key={Math.round(Math.random() * 100)}>
                    <td key="THT_18" onClick={() => { callback('18') }} className={ `${values.includes('18') ? 'teethtr' : border} ${isJobcard ? (values.includes('18') ? '' : 'text-white') : '' }` }>18</td>
                    <td key="THT_17" onClick={() => { callback('17') }} className={ `${values.includes('17') ? 'teethtr' : border} ${isJobcard ? (values.includes('17') ? '' : 'text-white') : '' }` }>17</td>
                    <td key="THT_16" onClick={() => { callback('16') }} className={ `${values.includes('16') ? 'teethtr' : border} ${isJobcard ? (values.includes('16') ? '' : 'text-white') : '' }` }>16</td>
                    <td key="THT_15" onClick={() => { callback('15') }} className={ `${values.includes('15') ? 'teethtr' : border} ${isJobcard ? (values.includes('15') ? '' : 'text-white') : '' }` }>15</td>
                    <td key="THT_14" onClick={() => { callback('14') }} className={ `${values.includes('14') ? 'teethtr' : border} ${isJobcard ? (values.includes('14') ? '' : 'text-white') : '' }` }>14</td>
                    <td key="THT_13" onClick={() => { callback('13') }} className={ `${values.includes('13') ? 'teethtr' : border} ${isJobcard ? (values.includes('13') ? '' : 'text-white') : '' }` }>13</td>
                    <td key="THT_12" onClick={() => { callback('12') }} className={ `${values.includes('12') ? 'teethtr' : border} ${isJobcard ? (values.includes('12') ? '' : 'text-white') : '' }` }>12</td>
                    <td key="THT_11" onClick={() => { callback('11') }} className={ `${values.includes('11') ? 'teethtr' : border} ${isJobcard ? (values.includes('11') ? '' : 'text-white') : '' }` }>11</td>
                    <td key="THT_21" onClick={() => { callback('21') }} className={ `${values.includes('21') ? 'teethtr' : border} ${isJobcard ? (values.includes('21') ? '' : 'text-white') : '' }` }>21</td>
                    <td key="THT_22" onClick={() => { callback('22') }} className={ `${values.includes('22') ? 'teethtr' : border} ${isJobcard ? (values.includes('22') ? '' : 'text-white') : '' }` }>22</td>
                    <td key="THT_23" onClick={() => { callback('23') }} className={ `${values.includes('23') ? 'teethtr' : border} ${isJobcard ? (values.includes('23') ? '' : 'text-white') : '' }` }>23</td>
                    <td key="THT_24" onClick={() => { callback('24') }} className={ `${values.includes('24') ? 'teethtr' : border} ${isJobcard ? (values.includes('24') ? '' : 'text-white') : '' }` }>24</td>
                    <td key="THT_25" onClick={() => { callback('25') }} className={ `${values.includes('25') ? 'teethtr' : border} ${isJobcard ? (values.includes('25') ? '' : 'text-white') : '' }` }>25</td>
                    <td key="THT_26" onClick={() => { callback('26') }} className={ `${values.includes('26') ? 'teethtr' : border} ${isJobcard ? (values.includes('26') ? '' : 'text-white') : '' }` }>26</td>
                    <td key="THT_27" onClick={() => { callback('27') }} className={ `${values.includes('27') ? 'teethtr' : border} ${isJobcard ? (values.includes('27') ? '' : 'text-white') : '' }` }>27</td>
                    <td key="THT_28" onClick={() => { callback('28') }} className={ `${values.includes('28') ? 'teethtr' : border} ${isJobcard ? (values.includes('28') ? '' : 'text-white') : '' }` }>28</td>
                </tr>
                <tr key={Math.round(Math.random() * 100)}>
                    <td key="THT_48" onClick={() => { callback('48')}} className={`${ values.includes('48') ? 'teethtr' : border } ${isJobcard ? (values.includes('48') ? '' : 'text-white') : ''}`}>48</td>
                    <td key="THT_47" onClick={() => { callback('47')}} className={`${ values.includes('47') ? 'teethtr' : border } ${isJobcard ? (values.includes('47') ? '' : 'text-white') : ''}`}>47</td>
                    <td key="THT_46" onClick={() => { callback('46')}} className={`${ values.includes('46') ? 'teethtr' : border } ${isJobcard ? (values.includes('46') ? '' : 'text-white') : ''}`}>46</td>
                    <td key="THT_45" onClick={() => { callback('45')}} className={`${ values.includes('45') ? 'teethtr' : border } ${isJobcard ? (values.includes('45') ? '' : 'text-white') : ''}`}>45</td>
                    <td key="THT_44" onClick={() => { callback('44')}} className={`${ values.includes('44') ? 'teethtr' : border } ${isJobcard ? (values.includes('44') ? '' : 'text-white') : ''}`}>44</td>
                    <td key="THT_43" onClick={() => { callback('43')}} className={`${ values.includes('43') ? 'teethtr' : border } ${isJobcard ? (values.includes('43') ? '' : 'text-white') : ''}`}>43</td>
                    <td key="THT_42" onClick={() => { callback('42')}} className={`${ values.includes('42') ? 'teethtr' : border } ${isJobcard ? (values.includes('42') ? '' : 'text-white') : ''}`}>42</td>
                    <td key="THT_41" onClick={() => { callback('41')}} className={`${ values.includes('41') ? 'teethtr' : border } ${isJobcard ? (values.includes('41') ? '' : 'text-white') : ''}`}>41</td>
                    <td key="THT_31" onClick={() => { callback('31')}} className={`${ values.includes('31') ? 'teethtr' : border } ${isJobcard ? (values.includes('31') ? '' : 'text-white') : ''}`}>31</td>
                    <td key="THT_32" onClick={() => { callback('32')}} className={`${ values.includes('32') ? 'teethtr' : border } ${isJobcard ? (values.includes('32') ? '' : 'text-white') : ''}`}>32</td>
                    <td key="THT_33" onClick={() => { callback('33')}} className={`${ values.includes('33') ? 'teethtr' : border } ${isJobcard ? (values.includes('33') ? '' : 'text-white') : ''}`}>33</td>
                    <td key="THT_34" onClick={() => { callback('34')}} className={`${ values.includes('34') ? 'teethtr' : border } ${isJobcard ? (values.includes('34') ? '' : 'text-white') : ''}`}>34</td>
                    <td key="THT_35" onClick={() => { callback('35')}} className={`${ values.includes('35') ? 'teethtr' : border } ${isJobcard ? (values.includes('35') ? '' : 'text-white') : ''}`}>35</td>
                    <td key="THT_36" onClick={() => { callback('36')}} className={`${ values.includes('36') ? 'teethtr' : border } ${isJobcard ? (values.includes('36') ? '' : 'text-white') : ''}`}>36</td>
                    <td key="THT_37" onClick={() => { callback('37')}} className={`${ values.includes('37') ? 'teethtr' : border } ${isJobcard ? (values.includes('37') ? '' : 'text-white') : ''}`}>37</td>
                    <td key="THT_38" onClick={() => { callback('38')}} className={`${ values.includes('38') ? 'teethtr' : border } ${isJobcard ? (values.includes('38') ? '' : 'text-white') : ''}`}>38</td>
                </tr>
            </tbody>
        </table>
    )
}

export default Teeth