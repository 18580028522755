import { reportWorkTypes } from "constants/layout";
import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap";
import { getBranches, getOptions } from "./optionsServices";
import "flatpickr/dist/themes/material_red.css"
import Flatpickr from "react-flatpickr"
import Timezone from "utils/timezone";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";

const initObj = {
    type: 'all',
    branch: 'all',
    dates: [Timezone(new Date()), Timezone(new Date())],
    client: '',
    category: '',
    producttype: '',
    product: ''
}

export const Filters = ({callback}) => {
    const [branches, setBranches] = useState([]);
    const [clients, setClients] = useState([]);
    const [categories, setCategories] = useState([]);
    const [productTypes , setProductTypes] = useState([]);
    const [products , setProducts] = useState([]);
    const [cities , setCities] = useState([]);
    const [multiSelected , setMultiSelected] = useState([]);

    const [filterData, setFilterData] = useState(initObj);

    useEffect(async() => {
        setBranches(await getBranches())
        callback(filterData)
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const clients = await getOptions(filterData.branch, 'clients');
            const categories = await getOptions(filterData.branch, 'categories');
            const productTypes = await getOptions(filterData.branch, 'productTypes');
            const products = await getOptions(filterData.branch, 'products');
            const Cities = await getOptions(filterData.branch, 'cities');
            
            setCities(Cities);
            setClients(clients);
            setCategories(categories);
            setProductTypes(productTypes);
            setProducts(products);
        };
    
        fetchData();
    }, [filterData.branch]);    

    useEffect(async() => {
        if(filterData.category != ''){
            setProductTypes(await getOptions(filterData.branch, 'productTypes', {'category': filterData.category}))
        }
    }, [filterData.category])

    useEffect(async() => {
        if(filterData.producttype != '' && filterData.category != ''){
            setProducts(await getOptions(filterData.branch, 'products', {'category': filterData.category, 'producttype': filterData.producttype}))
        }
    }, [filterData.producttype])

    return(
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <Row>
                        <Col className={'mb-3'}>
                                <label>Order Type</label>
                                <select className="form-control" onChange={(e) => {setFilterData(prev => ({...prev, type: e.target.value}))}}>
                                {
                                    reportWorkTypes?.map((v, i) => <option key={`WT_${i}`} value={v.id}>{v.title}</option>)
                                }
                                </select>
                            </Col>
                            {/* <Col className={'mb-3'}>
                                <label>Select Branch</label>
                                <MultiSelect
                                    options={ branches === undefined ? [] : branches}
                                    value={filterData.branch || []}
                                    onChange={(e) => {
                                        setMultiSelected(prev => ({...prev, branch:e}))
                                        setFilterData(prev => ({...prev, branch:e}))
                                    }}
                                    required={false}
                                    labelledBy="Select"
                                />
                            </Col> */}
                            <Col className={'mb-3'}>
                                <label>Select City</label>
                                <MultiSelect
                                    options={ cities === undefined ? [] : cities}
                                    value={filterData.city || []}
                                    onChange={(e) => {
                                        setMultiSelected(prev => ({...prev, city:e}))
                                        setFilterData(prev => ({...prev, city:e}))
                                    }}
                                    required={false}
                                    labelledBy="Select"
                                />
                            </Col>
                            <Col className={'mb-3'}>
                                <label>Select Client</label>
                                <MultiSelect
                                    options={ clients === undefined ? [] : clients}
                                    value={filterData.client || []}
                                    onChange={(e) => {
                                        setMultiSelected(prev => ({...prev, client:e}))
                                        setFilterData(prev => ({...prev, client:e}))
                                    }}
                                    required={false}
                                    labelledBy="Select"
                                />
                            </Col>
                            
                            <Col className={'mb-3'}>
                                <label>Date Range</label>
                                <Flatpickr
                                    className="form-control d-block"
                                    placeholder="DD-MM-YYYY"
                                    options={{
                                        mode: "range",
                                        dateFormat: "d-m-Y"
                                    }}
                                    // value={[moment(filterData?.dates[0]).format('DD-MM-YYYY'), moment(filterData?.dates[1]).format('DD-MM-YYYY')]}
                                    onChange={
                                        (e) => {
                                            const dates = e.map(dateString => Timezone(dateString));
                                            setFilterData(prev => ({...prev, dates: dates}))
                                        }
                                    }
                                />
                            </Col>

                            <Col className={'mb-4'}>
                                <label>Product Category</label>
                                <MultiSelect
                                    options={ categories === undefined ? [] : categories}
                                    value={filterData.category || [] }
                                    onChange={(e) => {
                                        setMultiSelected(prev => ({...prev, category:e}))
                                        setFilterData(prev => ({...prev, category:e}))
                                    }}
                                    required={false}
                                    labelledBy="Select"
                                />
                            </Col>
                            
                            <Col className={'mb-3'}>
                                <label>Product type</label>
                                <MultiSelect
                                    options={ productTypes === undefined ? [] : productTypes}
                                    value={filterData.producttype || [] }
                                    onChange={(e) => {
                                        setMultiSelected(prev => ({...prev, producttype:e}))
                                        setFilterData(prev => ({...prev, producttype:e}))
                                    }}
                                    required={false}
                                    labelledBy="Select"
                                />
                            </Col>
                            
                            <Col className={'mb-3'}>
                                <label>Product</label>
                                <MultiSelect
                                    options={ products === undefined ? [] : products}
                                    value={filterData?.product || []}
                                    onChange={(e) => {
                                        setMultiSelected(prev => ({...prev, product:e}))
                                        setFilterData(prev => ({...prev, product:e}))
                                    }}
                                    required={false}
                                    labelledBy="Select"
                                />
                            </Col>

                            <Col md={1} style={{marginTop: '24px', width: '142px'}}>
                                <button className="btn btn-primary float-end" onClick={() => callback(filterData)} > Submit</button>
                                <button className="btn btn-primary float-end mdi mdi-refresh me-1" title="reset filters" onClick={() => setFilterData(initObj)} ></button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}